import styled from '@emotion/styled';

export const Layout = styled.div`
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr auto;
`;

export default Layout;
