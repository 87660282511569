import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  Collapse,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { Menu } from '@material-ui/icons';
import { Header } from 'src/components/layout';
import LogoLasNipasHeader from '../../../../images/logo/logo-las-nipas-header.png';
import { MAIN_COLOR } from 'src/consts';
import { LanguageSelect } from 'src/components/LanguageSelect';
import { useText } from 'src/components/TextContext';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const HeaderLinkItem = ({
  to = '',
  backgroundVisibility = 0,
  linkProps = {},
  ...props
}) => (
  <StyledLink to={to} {...linkProps}>
    <Typography
      css={css`
        font-weight: bold;
        color: ${backgroundVisibility ? 'black' : 'white'};
      `}
      {...props}
    />
  </StyledLink>
);

export const IndexHeader = ({
  backgroundVisibility: propsBackgroundVisibility = false,
  location = { pathname: '/' },
  ...props
}) => {
  const t = useText();
  const triggerValue = 100;
  const appBarHeight = '3rem';
  let [backgroundVisibility, setBackgroundVisibility] = useState(0);
  const { scrollY } = useViewportScroll();
  const scrollYMotion = useTransform(scrollY, (value) =>
    value > triggerValue ? 1 : 0
  );

  useEffect(() => {
    if (propsBackgroundVisibility) return;
    scrollYMotion.onChange((scrollYValue) => {
      setBackgroundVisibility(scrollYValue);
    });
  }, [scrollYMotion]);

  if (propsBackgroundVisibility) {
    backgroundVisibility = 1;
  }

  const { links = [] } = t?.appbar;

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [linksOpen, setLinksOpen] = useState(false);
  const toggleOpenLinks = () => {
    setLinksOpen(!linksOpen);
  };

  return (
    <Header {...props}>
      <>
        <motion.div
          animate={{ opacity: backgroundVisibility }}
          initial={{ opacity: 0 }}
          css={css`
            z-index: 1100;
          `}
        >
          <AppBar
            css={css`
              height: ${appBarHeight};
              background-color: white;
            `}
          >
            <Toolbar />
          </AppBar>
        </motion.div>
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: ${appBarHeight};
            display: flex;
            align-items: center;
            z-index: 1101;
            padding: 0 1rem;
          `}
        >
          {backgroundVisibility ? (
            <StyledLink
              to="/"
              css={css`
                display: flex;
              `}
            >
              <img
                src={LogoLasNipasHeader}
                css={css`
                  height: 2.5rem;
                  margin-top: auto;
                  margin-left: auto;
                `}
              />
            </StyledLink>
          ) : null}
          <div
            css={css`
              display: grid;
              margin-left: auto;
              grid-auto-flow: column;
              grid-gap: 1rem;
              text-transform: uppercase;
              font-weight: bold;
              align-items: center;
            `}
          >
            {' '}
            {isMobile ? (
              <>
                <LanguageSelect />

                <IconButton>
                  <Menu
                    css={css`
                      color: ${backgroundVisibility ? 'black' : 'white'};
                    `}
                    onClick={toggleOpenLinks}
                  />
                </IconButton>
              </>
            ) : (
              <>
                {links.map((link) => (
                  <HeaderLinkItem
                    key={link.to}
                    to={link.to}
                    backgroundVisibility={backgroundVisibility}
                    css={css`
                      ${location.pathname == link.to && `color: ${MAIN_COLOR};`}
                      ${link.to == '/' &&
                      !backgroundVisibility &&
                      `color: white`}
                    `}
                  >
                    {link.name}
                  </HeaderLinkItem>
                ))}

                <LanguageSelect />
              </>
            )}
            <Collapse
              in={linksOpen}
              css={css`position: absolute; 
                      top: ${appBarHeight}; 
                      left: 0;
                      /* visibility: ${linksOpen ? 'visible' : 'hidden'}; */
                      width: 100%;;
                      ${backgroundVisibility && `background-color: white;`}
                      display: flex;
                      flex-flow: column;
                      align-items: center;
           `}
            >
              <List
                css={css`
                  z-index: 1111;
                  width: 100%;
                `}
              >
                {links.map((link) => (
                  <ListItem button alignItems="center" key={link.to}>
                    <HeaderLinkItem
                      to={link.to}
                      backgroundVisibility={backgroundVisibility}
                      css={css`
                        width: 100%;
                        text-align: center;
                        ${backgroundVisibility && `color: black;`}

                        ${location.pathname == link.to &&
                        `color: ${MAIN_COLOR};`}
                      `}
                      linkProps={{ style: { width: '100%' } }}
                    >
                      {link.name}
                    </HeaderLinkItem>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        </div>
      </>
    </Header>
  );
};

export default IndexHeader;
