import React from 'react';
import { css } from '@emotion/core';
import { Footer } from 'src/components/layout';
import { Typography } from '@material-ui/core';
import { useText } from 'src/components/TextContext';

export const IndexFooter = ({ ...props }) => {
  const t = useText();
  return (
    <Footer
      css={css`
        width: 100%;
        height: 5rem;
        display: flex;
      `}
      {...props}
    >
      <Typography
        variant="h5"
        css={css`
          margin: auto;
          text-align: center;
        `}
      >
        {t.footer.content}
      </Typography>
    </Footer>
  );
};

export default IndexFooter;
