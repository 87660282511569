import styled from '@emotion/styled';

export const Header = styled.header`
  grid-row: 1;
  grid-column: 1 / 4;
  display: flex;
  align-items: center;
`;

export default Header;
