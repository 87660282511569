import React from 'react';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';

import { muiTheme } from './muiTheme';

export const RootWrapper = ({ children }) => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default RootWrapper;
