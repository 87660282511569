import React, { useContext, useReducer, useEffect } from 'react';
import { useLocalStorageState } from 'src/util/useLocalStorageState';

export type State = {
  currentLanguage: string;
  text: { [x: string]: { [y: string]: any } };
};

export type Action = { type: 'LANGUAGE_SELECT'; lang: string };

const initialState: State = {
  currentLanguage: 'es',
  text: {
    es: {},
    en: {},
  },
};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'LANGUAGE_SELECT': {
      const { lang } = action;
      if (!(lang in state.text)) return state;
      return {
        ...state,
        currentLanguage: lang,
      };
    }
    default:
      return state;
  }
};

export const TextContext = React.createContext<
  State & { dispatch: React.Dispatch<Action> }
>({ ...initialState, dispatch: () => null });

export const TextContextProvider = ({ text, initialLanguage = 'en', ...props }) => {
  const [currentLanguage, setCurrentLanguage] = useLocalStorageState(
    initialLanguage || 'en',
    'currentLanguage'
  );

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    currentLanguage,
    ...({ text } ?? {}),
  });

  useEffect(() => {
    setCurrentLanguage(state.currentLanguage);
  }, [state.currentLanguage]);

  return <TextContext.Provider value={{ ...state, dispatch }} {...props} />;
};

export const useText = () => {
  const { text, currentLanguage } = useContext(TextContext);
  return text[currentLanguage];
};

export const useLanguage = () => {
  const { currentLanguage, dispatch } = useContext(TextContext);

  const setLanguage = (lang) => {
    dispatch({ type: 'LANGUAGE_SELECT', lang });
  };

  return { currentLanguage, setLanguage };
};

export default TextContext;
