import styled from '@emotion/styled';

export const Main = styled.main`
  grid-row: 2;
  grid-column: 1 / 4;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
`;

export default Main;
